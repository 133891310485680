import React from 'react';
import { useParams } from 'react-router-dom';

import BalanceDetailsControl from '@controls/balance-details/balance-details';

const BalanceDetails = () => {
    const { userId } = useParams();

    return <BalanceDetailsControl userId={userId || ''} isViewMode={false} showExchangeRateInfo={true} />;
};

export default BalanceDetails;
