import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import type { To } from '@remix-run/router';

type ProtectedRouteProps = {
    isAllowed?: boolean;
    redirectPath: To;
    children?: React.ReactNode;
};

export const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { isAllowed, redirectPath, children } = props;
    if (!isAllowed) {
        return <Navigate replace to={redirectPath} />;
    }

    return children ? children : <Outlet />;
};
