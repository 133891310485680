import React, { CSSProperties } from 'react';

import { Flex, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { BankOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import { toFinanceString } from '@extensions/utils';

import { Currency, enumSign as currencySign } from '@enums/currency';
import { UserAccountType, enumLabel as accountTypeLabel } from '@enums/user-account-type';

import { TruckIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

interface IAccountcontrol {
    type: UserAccountType;
    currency: Currency;
    amount?: number;
    tooltipPlacement?: TooltipPlacement;
    tooltipTitle?: string;
    size?: SizeType;
}

const Account = (props: IAccountcontrol) => {
    const { currency, amount, type, tooltipPlacement, tooltipTitle, size } = props;

    const getTooltipTitle = (currency: Currency, type?: UserAccountType) => {
        if (tooltipTitle) return tooltipTitle;

        return `${accountTypeLabel(type)} (${currencySign(currency)})`;
    };

    const renderCurrencyIcon = (currency: Currency) => {
        switch (currency) {
            case Currency.Rub: {
                return <RubleFilledIcon style={{ fontSize: !size || size == 'small' ? 18 : 28, marginRight: 2 }} />;
            }
            case Currency.Usd: {
                return <UsdFilledIcon style={{ fontSize: !size || size == 'small' ? 18 : 28, marginRight: 2 }} />;
            }
            case Currency.Eur: {
                return <EuroFilledIcon style={{ fontSize: !size || size == 'small' ? 18 : 28, marginRight: 2 }} />;
            }
        }
    };

    const renderIcon = (type: UserAccountType, currency: Currency) => {
        switch (type) {
            case UserAccountType.User: {
                return renderCurrencyIcon(currency);
            }

            case UserAccountType.Transport: {
                return <TruckIcon style={{ fontSize: !size || size == 'small' ? 18 : 28, color: 'var(--main-green)', marginRight: 2 }} />;
            }
            case UserAccountType.Transfer: {
                return <BankOutlined style={{ fontSize: !size || size == 'small' ? 16 : 26, color: '#6a329f', marginRight: 2 }} />;
            }
            default: {
                return <></>;
            }
        }
    };

    return (
        <Tooltip title={getTooltipTitle(currency, type)} placement={tooltipPlacement || 'left'}>
            <Flex align='center'>
                {renderIcon(type, currency)}

                {amount == undefined ? (
                    <></>
                ) : (
                    <div
                        style={{
                            marginLeft: 5,
                            fontSize: !size || size == 'small' ? 14 : 18,
                            fontWeight: 600,
                            color: amount < 0 ? 'var(--main-red)' : 'var(--main-green)',
                        }}
                    >
                        {toFinanceString(amount || 0, 2)}
                    </div>
                )}
            </Flex>
        </Tooltip>
    );
};

export default Account;
