export enum CargoCarrierType {
    Air = 1,
    Truck = 2,
}

export const enumLabel = (value?: CargoCarrierType) => {
    switch (value) {
        case CargoCarrierType.Air:
            return 'Авиа';
        case CargoCarrierType.Truck:
            return 'Транспорт';
        default:
            return '';
    }
};
