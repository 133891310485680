export enum WaybillType {
    AWB = 1,
}

export const enumLabel = (value?: WaybillType) => {
    switch (value) {
        case WaybillType.AWB:
            return 'AWB';

        default:
            return '';
    }
};
