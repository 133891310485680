import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, InputNumber, Input, Form, Space, DatePicker } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IWaybill } from '@entities/waybill';

import { Permission, hasPermission } from '@enums/permission';
import { WaybillType } from '@enums/waybill-type';
import { Currency, enumSign as currencySign } from '@enums/currency';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Waybill = () => {
    const { id, routeZone, headerId } = useParams();

    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IWaybill>({
        id: undefined,
        type: WaybillType.AWB,
        amount: undefined,
        currency: Currency.Usd,
        dateOn: undefined,
        number: undefined,
        qty: undefined,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageWaybill));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [];

            if (id) {
                promises.push(
                    await serverFetch(`waybills/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения накладной', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                if (id) setEntity(result[0][0]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onFinish = () => {
        setLoading(true);

        serverFetch(`waybills`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения накладной', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о накладной' : 'Изменить накладную') : 'Добавить накладную'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 5 }} onFinish={onFinish}>
                    <Form.Item
                        label='Дата'
                        name='dateOn'
                        rules={[{ required: true, message: 'Укажите дату' }]}
                        initialValue={entity.dateOn && dayjs(entity.dateOn).utc(true).set('hour', 0).set('minute', 0).set('second', 0)}
                    >
                        <DatePicker
                            autoFocus
                            disabled={isViewOnly}
                            format={'DD.MM.YYYY'}
                            onChange={(data) => {
                                setEntity({
                                    ...entity,
                                    dateOn: dayjs(data).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.number}
                        label='Номер'
                        name='number'
                        rules={[{ required: true, message: 'Укажите номер' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, number: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.qty}
                        label='Количество'
                        name='qty'
                        rules={[{ required: true, message: 'Укажите количество' }]}
                    >
                        <InputNumber
                            disabled={isViewOnly}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, qty: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Вес Брутто' name='grossWeight' initialValue={entity.grossWeight}>
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            stringMode
                            precision={2}
                            disabled={isViewOnly}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, grossWeight: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Объемный Вес' name='volumeWeight' initialValue={entity.volumeWeight}>
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            stringMode
                            precision={2}
                            disabled={isViewOnly}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, volumeWeight: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Сумма'
                        name='amount'
                        initialValue={entity.amount}
                        rules={[{ required: true, message: 'Укажите сумму' }]}
                    >
                        <InputNumber
                            decimalSeparator=','
                            min={0}
                            stringMode
                            precision={2}
                            disabled={isViewOnly}
                            addonAfter={currencySign(Currency.Usd)}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, amount: value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 3, span: 5 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Waybill;
