import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { notification } from 'antd';

import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, securityRestriction } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { ICity } from '@entities/city';

import { serverFetch } from '@src/core/server';

const Cities = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [cities, setCities] = useState<Array<ICity>>([]);
    const [currentCity, setCurrentCity] = useState<ICity>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    const init = () => {
        setCities([]);

        setLoading(true);

        serverFetch('cities', { method: 'GET' })
            .then((data) => {
                setCities(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onDelete = () => {
        if (!currentCity) return;

        serverFetch(`cities/${currentCity.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления города', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageCities)) {
                                securityRestriction(api, [Permission.ManageCities]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageCities) ? 'Изменить' : 'Информация о городе'}`,
                        key: 'edit',
                        disabled: !currentCity,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentCity?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentCity,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteCity)) {
                                securityRestriction(api, [Permission.DeleteCity]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить город "${currentCity?.name}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = cities.find((o) => o.id == selectedRowKeys[0]);
            setCurrentCity(entity);
        } else {
            setCurrentCity(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ICity> = [
            {
                title: 'Название',
                dataIndex: 'name',
                width: 200,
            },
            {
                title: 'Расстояние / км',
                dataIndex: 'distance',
                align: 'center',
                width: 200,
            },
            { title: ' ' },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={cities}
                loading={tableLoading}
                onRow={(record: ICity) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Cities;
