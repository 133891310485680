import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Button, Input, Form, Space, Select, InputNumber } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { getEnumList } from '@extensions/utils';
import { exception } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IItem } from '@entities/item';

import { UnitType, enumLabel as unitTypeLabel } from '@enums/unit-type';
import { IEnumItem } from '@enums/enum-item';

const Item = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [entity, setEntity] = useState<IItem>({
        id: undefined,
        name: undefined,
        code: undefined,
        sequence: undefined,
        unitType: undefined,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageItems));
    const [unitTypes] = useState<Array<IEnumItem>>(getEnumList(UnitType, unitTypeLabel));

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        if (!id) {
            return;
        }

        setLoading(true);

        serverFetch(`items/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения продукта', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onSave = () => {
        setLoading(true);

        serverFetch(`items`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения продукта', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о продукте' : 'Изменить продукт') : 'Добавить продукт'}`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity.name}
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название продукта' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.code}
                        required
                        label='Код'
                        name='code'
                        rules={[{ required: true, message: 'Укажите код продукта' }]}
                    >
                        <Input
                            disabled={isViewOnly}
                            onChange={(data) => {
                                setEntity({ ...entity, code: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.unitType}
                        required
                        label='Единица товара'
                        name='unitType'
                        rules={[{ required: true, message: 'Выберите единицу товара' }]}
                    >
                        <Select
                            showSearch
                            onChange={(value) => setEntity({ ...entity, unitType: value })}
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={unitTypes.map((c) => {
                                return { value: c.value, label: c.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.sequence}
                        label='Порядковый номер'
                        name='sequence'
                        required
                        rules={[{ required: true, message: 'Укажите порядковый номер' }]}
                    >
                        <InputNumber
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, sequence: value as number });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='text' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Item;
