import React from 'react';
import Icon from '@ant-design/icons';

const WalletIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, ...props.style }} />;
};
export default WalletIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Icons' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <path d='M30,15v-1c0-1.7-1.3-3-3-3h-7.6l-7.8-7C10.1,2.7,8,2.3,6.3,3.1l-4.6,2C1.1,5.3,0.9,5.9,1.1,6.4c0.2,0.5,0.8,0.7,1.3,0.5 l4.6-2c1.1-0.5,2.3-0.3,3.2,0.5l6.2,5.5h-3.8h-1.2L9.7,9.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L8.6,11H6c-0.6,0-1,0.4-1,1s0.4,1,1,1 h6.6c1.3,0,2.4,1.1,2.4,2.4c0,0.3-0.3,0.6-0.6,0.6H7c-0.4,0-0.7,0.2-0.9,0.5C5.2,18.1,3.7,19,2,19c-0.6,0-1,0.4-1,1s0.4,1,1,1v6 c0,1.7,1.3,3,3,3h22c1.7,0,3-1.3,3-3V17h-2H16.4c0.4-0.4,0.6-1,0.6-1.6c0-0.1,0-0.2,0-0.4h11H30z M19,20c0.8,0,1.5,0.3,2,0.8 c0.5-0.5,1.2-0.8,2-0.8c1.7,0,3,1.3,3,3s-1.3,3-3,3c-0.8,0-1.5-0.3-2-0.8c-0.5,0.5-1.2,0.8-2,0.8c-1.7,0-3-1.3-3-3S17.3,20,19,20z'></path>{' '}
        </g>
    </svg>
);
