import React from 'react';
import Icon from '@ant-design/icons';

const ImportTruckIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, ...props.style }} />;
};
export default ImportTruckIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 612'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='M12.57,385.084h316.849c4.735,0,8.61-3.875,8.61-8.609V119.809c0-14.266-11.564-25.83-25.83-25.83H29.791 c-14.266,0-25.83,11.564-25.83,25.83v256.664C3.96,381.209,7.835,385.084,12.57,385.084z M76.417,212.128 c0-5.902,4.784-10.687,10.687-10.687h84.528v-30.118c0-9.521,11.514-14.289,18.245-7.554l72.838,72.873 c4.171,4.173,4.171,10.938,0,15.11l-72.838,72.861c-6.732,6.734-18.245,1.967-18.245-7.555v-30.125H87.103 c-5.902,0-10.687-4.785-10.687-10.687V212.128z M113.48,454.221H8.61c-4.736,0-8.61-3.873-8.61-8.609v-26.174 c0-4.736,3.874-8.611,8.61-8.611h128.634C124.673,421.762,115.977,437.002,113.48,454.221z M603.39,410.828h-20.922V293.817 c0-13.689-4.133-27.121-11.796-38.486l-52.434-77.663c-12.83-18.942-34.183-30.307-57.085-30.307h-81.278 c-9.558,0-17.221,7.749-17.221,17.22v246.247H229.543c12.657,10.934,21.353,26.174,23.85,43.395h140.343 c4.994-34.182,34.44-60.615,70-60.615s64.919,26.434,69.913,60.615h69.741c4.736,0,8.61-3.875,8.61-8.611v-26.174 C612,414.703,608.126,410.828,603.39,410.828z M517.634,270.828h-107.28c-4.735,0-8.61-3.789-8.61-8.61v-59.495 c0-4.735,3.875-8.61,8.61-8.61h65.351c2.841,0,5.424,1.377,7.06,3.616l41.931,59.582 C528.654,262.994,524.608,270.828,517.634,270.828z M183.394,410.828c-29.532,0-53.555,24.021-53.555,53.641 c0,29.531,24.022,53.553,53.555,53.553c29.618,0,53.554-24.021,53.554-53.553C236.947,434.85,213.012,410.828,183.394,410.828z M183.394,491.246c-14.81,0-26.777-12.055-26.777-26.777c0-14.811,11.968-26.777,26.777-26.777 c14.809,0,26.777,11.967,26.777,26.777C210.171,479.191,198.202,491.246,183.394,491.246z M463.735,410.828 c-29.618,0-53.555,24.021-53.555,53.641c0,29.531,23.937,53.553,53.555,53.553c29.532,0,53.554-24.021,53.554-53.553 C517.29,434.85,493.269,410.828,463.735,410.828z M463.735,491.246c-14.81,0-26.777-12.055-26.777-26.777 c0-14.811,11.968-26.777,26.777-26.777c14.809,0,26.777,11.967,26.777,26.777C490.513,479.191,478.545,491.246,463.735,491.246z'></path>
            </g>
        </g>
    </svg>
);
