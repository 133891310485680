import React, { useState, useEffect } from 'react';

import { Modal, Input, Form, Divider, Select, Table, notification, InputNumber } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined, PlusOutlined, LoadingOutlined, QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { getEnumList, toFinanceString } from '@extensions/utils';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserDebt } from '@entities/user-debt';
import { IDebt } from '@entities/debt';

import { Currency, enumSign as currencySign, enumLabel as currencyLabel } from '@enums/currency';
import { IEnumItem } from '@enums/enum-item';

interface IDebtsProps {
    userId: string;
    isViewOnly: boolean;
    userDebts: Array<IUserDebt>;
    onRefresh: () => void;
}

const Debts = (props: IDebtsProps) => {
    const { TextArea } = Input;

    const { userId, isViewOnly, userDebts, onRefresh } = props;

    const [userDebtForm] = Form.useForm();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();

    const [api, contextHolder] = notification.useNotification();

    const [debts, setDebts] = useState<Array<IDebt>>([]);

    const [selectedUserDebtIds, setSelectedUserDebtIds] = useState<React.Key[]>([]);
    const [currentUserDebt, setCurrentUserDebt] = useState<IUserDebt>();

    const [userDebtOpen, setUserDebtOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [currencies] = useState<Array<IEnumItem>>(getEnumList(Currency, currencyLabel));

    useEffect(() => {
        serverFetch('debts', { method: 'GET' })
            .then((data) => {
                setDebts(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения типов долга', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    const onRefreshRequired = () => {
        setSelectedUserDebtIds([]);
        setCurrentUserDebt(undefined);
        onRefresh();
    };

    const onSave = (data: IUserDebt) => {
        if (!data) return;

        setLoading(true);

        data.userId = userId;

        serverFetch(`userdebts`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);

                onRefreshRequired();

                userDebtForm.resetFields();
                setUserDebtOpen(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения долга', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteUserDebt = () => {
        serverFetch(`userdebts/${currentUserDebt?.id}`, { method: 'DELETE' })
            .then(() => {
                onRefreshRequired();
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления долга', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUserDebtSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedUserDebtIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = userDebts.find((c) => c.id === selectedRowKeys[0]);
            setCurrentUserDebt(entity);
        } else {
            setCurrentUserDebt(undefined);
        }
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loading || !userId,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            onRefreshRequired();
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedUserDebtIds([]);
                            setCurrentUserDebt(undefined);

                            userDebtForm.setFieldValue('currency', Currency.Usd);
                            setUserDebtOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentUserDebt || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setUserDebtOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentUserDebt || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить долг "${currentUserDebt?.debtName}" на сумму ${currentUserDebt?.amount} ${currencySign(
                                    currentUserDebt?.currency
                                )}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteUserDebt();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserDebt> = [
            {
                title: 'Название',
                width: 200,
                render: (_: any, record) => {
                    return record.debtName;
                },
            },
            {
                title: 'Сумма',
                width: 120,
                render: (_: any, record) => {
                    return (
                        <>
                            {toFinanceString(record.amount, 2)} {currencySign(record.currency)}
                        </>
                    );
                },
            },
            {
                title: 'Комментарий',
                width: 400,
                key: 'comment',
                dataIndex: 'comment',
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={userDebts}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={
                    isViewOnly
                        ? undefined
                        : {
                              selectedRowKeys: selectedUserDebtIds,
                              onChange: onUserDebtSelectChange,
                              columnWidth: 35,
                              type: 'radio',
                          }
                }
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onUserDebtSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Divider style={{ background: 'var(--main-red)' }} orientation='left'>
                Долги перед клиентом
            </Divider>

            {!isViewOnly && renderToolbar()}
            {renderTable()}

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentUserDebt ? 'Изменить долг' : 'Добавить долг'}
                open={userDebtOpen}
                okText='ОК'
                onOk={() => userDebtForm.submit()}
                onCancel={() => {
                    userDebtForm.resetFields();
                    setUserDebtOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSave}
                    form={userDebtForm}
                >
                    <Form.Item hidden name='id' initialValue={currentUserDebt?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='currency' initialValue={currentUserDebt?.currency}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Тип'
                        initialValue={currentUserDebt?.debtId}
                        required
                        name='debtId'
                        rules={[{ required: true, message: 'Укажите тип долга' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            options={debts.map((d) => {
                                return { value: d.id, label: d.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Сумма'
                        initialValue={currentUserDebt?.amount}
                        name='amount'
                        rules={[{ required: true, message: 'Укажите сумму долга' }]}
                    >
                        <InputNumber
                            precision={2}
                            decimalSeparator=','
                            min={0}
                            style={{ width: '100%' }}
                            addonAfter={
                                <Select
                                    style={{ width: 90 }}
                                    defaultValue={currentUserDebt?.currency || Currency.Usd}
                                    onChange={(value: Currency) => {
                                        userDebtForm.setFieldValue('currency', value);
                                    }}
                                    options={currencies.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            }
                        />
                    </Form.Item>

                    <Form.Item label='Комментарий' initialValue={currentUserDebt?.comment} name='comment'>
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Debts;
