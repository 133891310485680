import React, { useState, useEffect } from 'react';

import { Modal, Switch, Input, Form, Divider, Table, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    LoadingOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, warning } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';

interface ICompaniesProps {
    userId: string | undefined;
    isViewOnly: boolean;
}

const Companies = (props: ICompaniesProps) => {
    const { userId, isViewOnly } = props;

    const { TextArea } = Input;

    const [companyForm] = Form.useForm();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [selectedCompanyIds, setSelectedCompanyIds] = useState<React.Key[]>([]);
    const [currentCompany, setCurrentCompany] = useState<ICompany>();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(!!userId);
    const [companyOpen, setCompanyOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setCompanies([]);

        setSelectedCompanyIds([]);
        setCurrentCompany(undefined);

        setRefreshRequired(false);

        setLoading(true);

        var companyFilter: ICompanyFilter = {
            userId: userId,
        };

        serverFetch(`companies`, { method: 'GET', queryParams: companyFilter })
            .then((data) => {
                setCompanies(data);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения контрагентов', ex, () => d(userLoaded(undefined)));
            });
    }, [refreshRequired]);

    const onSave = (data: ICompany) => {
        if (!data) return;

        setLoading(true);

        data.userId = userId;

        serverFetch(`companies`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);

                setRefreshRequired(true);

                companyForm.resetFields();
                setCompanyOpen(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения контрагента', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteCompany = () => {
        serverFetch(`companies/${currentCompany?.id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления контрагента', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompanySelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCompanyIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = companies.find((c) => c.id === selectedRowKeys[0]);
            setCurrentCompany(entity);
        } else {
            setCurrentCompany(undefined);
        }
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loading || !userId,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedCompanyIds([]);
                            setCurrentCompany(undefined);

                            setCompanyOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentCompany || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setCompanyOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentCompany || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (currentCompany?.isMain) {
                                warning(api, 'Нельзя удалить основного контрагента');
                                return;
                            }

                            modal.confirm({
                                title: `Удалить контрагента "${currentCompany?.inn}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteCompany();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'Название',
                dataIndex: 'legalName',
                width: 350,
            },
            {
                title: 'ИНН',
                width: 180,
                dataIndex: 'inn',
            },
            {
                title: 'Основной',
                width: 90,
                align: 'center',
                render: (_: any, record) => {
                    return record.isMain ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'Юр. адрес',
                dataIndex: 'legalAddress',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={companies}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={
                    isViewOnly
                        ? undefined
                        : {
                              selectedRowKeys: selectedCompanyIds,
                              onChange: onCompanySelectChange,
                              columnWidth: 35,
                              type: 'radio',
                          }
                }
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onCompanySelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            <Divider orientation='left'>Контрагенты</Divider>

            {!isViewOnly && renderToolbar()}
            {renderTable()}

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentCompany ? 'Изменить контрагента' : 'Добавить контрагента'}
                open={companyOpen}
                okText='ОК'
                onOk={() => companyForm.submit()}
                onCancel={() => {
                    companyForm.resetFields();
                    setCompanyOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSave}
                    form={companyForm}
                >
                    <Form.Item hidden name='id' initialValue={currentCompany?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={currentCompany?.legalName}
                        label='Название'
                        name='legalName'
                        required={true}
                        rules={[{ required: true, message: 'Укажите контрагента' }]}
                    >
                        <Input autoFocus disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        label='ИНН'
                        initialValue={currentCompany?.inn}
                        name='inn'
                        required={true}
                        rules={[{ required: true, message: 'Укажите ИНН' }]}
                    >
                        <Input disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        label='Юр. адрес'
                        initialValue={currentCompany?.legalAddress}
                        name='legalAddress'
                        required={true}
                        rules={[{ required: true, message: 'Укажите юридический адрес' }]}
                    >
                        <TextArea disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item label='Основной' name='IsMain' valuePropName='checked'>
                        <Switch disabled={isViewOnly} defaultChecked={currentCompany ? currentCompany.isMain : companies.length <= 0} />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Companies;
