import React, { useState, useEffect } from 'react';

import { Space, Button, Input, Form, Modal, Tooltip, AutoComplete, Flex } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { DeleteFilled, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { exception } from '@extensions/notification';
import { IOption } from '@extensions/option';

import { useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { ICargoCarrier } from '@entities/cargo-carrier';

import { CargoCarrierType } from '@enums/cargo-carrier-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ICargoCarrierControl {
    defaultCargoCarrierName: string | undefined;
    baseCargoCarriers: Array<ICargoCarrier>;
    canManageTruck: boolean;
    api: NotificationInstance;
    onSelect: (value: string | undefined) => void;
    loadCargoCarriers: () => void;
}

const CargoCarrierControl = (props: ICargoCarrierControl) => {
    const { baseCargoCarriers, defaultCargoCarrierName, loadCargoCarriers, onSelect, api, canManageTruck } = props;

    const [сargoCarrierForm] = Form.useForm();

    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();

    const [cargoCarriers, setCargoCarriers] = useState<Array<ICargoCarrier>>([]);
    const [currentCargoCarrier, setCurrentCargoCarrier] = useState<ICargoCarrier>();
    const [options, setOptions] = useState<Array<IOption>>([]);
    const [сargoCarrierOpen, setCargoCarrierOpen] = useState<boolean>(false);

    useEffect(() => {
        setCargoCarriers(baseCargoCarriers);
    }, [baseCargoCarriers]);

    useEffect(() => {
        let items: Array<IOption> = [];

        cargoCarriers.map((c: ICargoCarrier) => {
            let newItem: IOption = {
                value: `${c.name}`,
                label: (
                    <Flex gap='small'>
                        <Tooltip title='Удалить перевозчика' placement='left'>
                            <Button
                                type='link'
                                size='small'
                                icon={<DeleteFilled />}
                                hidden={!currentCargoCarrier || !canManageTruck}
                                onClick={() => {
                                    modal.confirm({
                                        title: `Удалить перевозчика "${c?.name}"?`,
                                        icon: <QuestionCircleOutlined />,
                                        okType: 'primary',
                                        okText: 'Удалить',
                                        cancelText: 'Отмена',
                                        onOk: () => {
                                            onDelete(c?.id);
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                        <div>{c.name}</div>
                    </Flex>
                ),
            };

            items.push(newItem);
        });

        setOptions(items);
    }, [cargoCarriers]);

    useEffect(() => {
        onSetCurrent(defaultCargoCarrierName);
    }, [defaultCargoCarrierName, cargoCarriers]);

    const onAdd = (entity: ICargoCarrier) => {
        serverFetch(`cargocarriers`, { method: 'POST', bodyData: entity })
            .then(() => {
                сargoCarrierForm.resetFields();
                setCargoCarrierOpen(false);
                loadCargoCarriers();
                onSelect(entity.name);
            })
            .catch((ex) => {
                exception(api, 'Ошибка добавления поставщика', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = (id: string | undefined) => {
        if (!id) return;

        serverFetch(`cargocarriers/${id}`, { method: 'DELETE' })
            .then(() => {
                onSelect(undefined);
                loadCargoCarriers();
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления поставщика', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSetCurrent = (name: string | undefined) => {
        if (!name) {
            setCurrentCargoCarrier(undefined);
            return;
        }

        var cargoCarrier = cargoCarriers.find((c) => c.name == name && c.type == CargoCarrierType.Truck);
        setCurrentCargoCarrier(cargoCarrier);
    };

    return (
        <>
            <Space.Compact style={{ width: '100%' }}>
                <AutoComplete
                    allowClear
                    value={currentCargoCarrier?.name}
                    disabled={!canManageTruck}
                    popupMatchSelectWidth={true}
                    options={options}
                    onSelect={onSelect}
                    onChange={(value: string | undefined) => {
                        if (!value) {
                            onSelect(undefined);
                            return;
                        }
                    }}
                    onClear={() => onSelect(undefined)}
                    onSearch={async (value: string) => {
                        var result = baseCargoCarriers?.filter((p) => p.name?.toUpperCase()?.startsWith(value.toUpperCase()));

                        setCargoCarriers(result || []);
                    }}
                />
                <Tooltip title='Добавить перевозчика'>
                    <Button
                        icon={<PlusOutlined />}
                        onClick={() => setCargoCarrierOpen(true)}
                        hidden={!currentCargoCarrier || !canManageTruck}
                    />
                </Tooltip>
            </Space.Compact>

            <Modal
                title='Транспортный перевозчик'
                open={сargoCarrierOpen}
                okText='ОК'
                onOk={() => сargoCarrierForm.submit()}
                onCancel={() => {
                    сargoCarrierForm.resetFields();

                    setCargoCarrierOpen(false);
                }}
            >
                <Form colon={false} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onAdd} form={сargoCarrierForm}>
                    <Form.Item hidden name='type' initialValue={CargoCarrierType.Truck}>
                        <Input />
                    </Form.Item>
                    <Form.Item required label='Название' name='name' rules={[{ required: true, message: 'Укажите название перевозчика' }]}>
                        <Input
                            onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase())}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            {modalContextHolder}
        </>
    );
};

export default CargoCarrierControl;
