import React, { useState, useEffect } from 'react';

import { Dayjs } from 'dayjs';

import { Table, Input, Tooltip, DatePicker, Empty, ConfigProvider, notification } from 'antd';
import { LoadingOutlined, ReloadOutlined, FilterFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { exception } from '@extensions/notification';
import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IAuditLog } from '@entities/audit-log';
import { IAuditLogFilter } from '@entities/audit-log-filter';

import { AuditLogReferenceType } from '@enums/audit-log-reference-type';

import { ImportTruckIcon, BillIcon, BoxesIcon, CartIcon, MovingIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const filterContext: string = 'AuditLogs';

const AuditLogs = () => {
    const { RangePicker } = DatePicker;

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const filter = useAppSelector<IAuditLogFilter>((s) => s.filters[filterContext]);

    const [logs, setLogs] = useState<Array<IAuditLog>>();
    const [loading, setLoading] = useState(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [showFilter, setShowFilter] = useState<boolean>(true);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('auditlogs', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения логов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setLogs(result[0][0]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                display={showFilter}
                items={[
                    <Input
                        style={{ width: 150 }}
                        key='referenceNumber'
                        placeholder='Номер'
                        value={filter?.referenceNumber}
                        onChange={(data) => {
                            d(setFilter({ ...filter, referenceNumber: data.target.value }, filterContext));
                        }}
                    />,
                    <Input
                        style={{ width: 250 }}
                        key='searchText'
                        placeholder='Действие...'
                        value={filter?.searchText}
                        onChange={(data) => {
                            d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                        }}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='createdOn'
                        format='DD.MM.YYYY'
                        placeholder={['Период с', 'по']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={[
                            filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                            filter?.createdTo ? dayjs(filter?.createdTo) : null,
                        ]}
                        onChange={(value) => {
                            if (!value) {
                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom: undefined,
                                            createdTo: undefined,
                                        },
                                        filterContext
                                    )
                                );
                                return;
                            }

                            d(
                                setFilter(
                                    {
                                        ...filter,
                                        createdFrom: value[0]
                                            ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                        createdTo: value[1]
                                            ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                            : undefined,
                                    },
                                    filterContext
                                )
                            );
                        }}
                    />,
                ]}
                onClear={() => d(setFilter({}, filterContext))}
            />
        );
    };

    const onRenderEmpty = () => <Empty description='Укажите критерии поиска...' />;

    const renderTable = () => {
        const columns: ColumnsType<IAuditLog> = [
            {
                title: '',
                width: 50,
                align: 'center',
                render: (_, record) => {
                    switch (record.referenceType) {
                        case AuditLogReferenceType.Truck:
                            return (
                                <Tooltip placement='top' title='Машина'>
                                    <div>
                                        <ImportTruckIcon style={{ color: 'var(--primary-color)' }} />
                                    </div>
                                </Tooltip>
                            );
                        case AuditLogReferenceType.Bill:
                            return (
                                <Tooltip placement='top' title='Счет'>
                                    <div>
                                        <BillIcon style={{ color: 'var(--main-green)' }} />
                                    </div>
                                </Tooltip>
                            );
                        case AuditLogReferenceType.BoxGroup:
                            return (
                                <Tooltip placement='top' title='Партия'>
                                    <div>
                                        <BoxesIcon />
                                    </div>
                                </Tooltip>
                            );
                        case AuditLogReferenceType.AdditionalLoad:
                            return (
                                <Tooltip placement='top' title='Догруз'>
                                    <div>
                                        <CartIcon />
                                    </div>
                                </Tooltip>
                            );
                        case AuditLogReferenceType.TransferBox:
                            return (
                                <Tooltip placement='top' title='перестановка'>
                                    <div>
                                        <MovingIcon />
                                    </div>
                                </Tooltip>
                            );
                        default:
                            return '';
                    }
                },
            },
            {
                title: 'Номер',
                width: 150,
                align: 'center',
                dataIndex: 'referenceNumber',
            },

            {
                title: 'Действие',
                dataIndex: 'action',
                width: 850,
            },
            {
                title: 'Инициатор',
                dataIndex: 'createdOnName',
                width: 250,
            },
            {
                title: 'Дата / Время',
                width: 150,
                render: (_, record) => {
                    return dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {},
        ];

        return (
            <ConfigProvider renderEmpty={!filter ? onRenderEmpty : undefined}>
                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={logs}
                    loading={{
                        spinning: loading,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    pagination={false}
                    scroll={{ y: `calc(100vh - ${showFilter ? 240 : 182}px)`, x: 'max-content' }}
                />
            </ConfigProvider>
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default AuditLogs;
