import React, { useState, useEffect } from 'react';

import { useAppDispatch } from '@store/hooks';

import { Form, Flex, Modal, Tag, InputNumber, Tooltip, Button } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { DollarOutlined } from '@ant-design/icons';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { ISystemSetting } from '@entities/system-setting';

import { Currency, enumSign as currencySign } from '@enums/currency';
import { SystemSettingType } from '@enums/system-setting-type';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IExchangeRateInfo {
    setInternalUsdExchangeRate: boolean;
    api: NotificationInstance;
    useInternalExchangeRate?: boolean;
    useCBExchangeRate?: boolean;
}

const ExchangeRateInfo = (props: IExchangeRateInfo) => {
    const { setInternalUsdExchangeRate, useInternalExchangeRate, useCBExchangeRate, api } = props;

    const d = useAppDispatch();

    const [internalUsdExchangeRateForm] = Form.useForm();
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [openInternalUsdExchangeRate, setOpenInternalUsdExchangeRate] = useState<boolean>(false);

    const [internalUsdExchangeRateSetting, setInternalUsdExchangeRateSetting] = useState<ISystemSetting>();
    const [cbUsdExchangeRate, setCbUsdExchangeRate] = useState<number>(0);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        const fetchData = async () => {
            let promises = [
                await serverFetch('systemSettings/cbusdexchangerate', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения курса ЦБ', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`systemSettings/${SystemSettingType.InternalExchangeRate}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения внутреннего курса', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCbUsdExchangeRate(result[0][0]);

                let internalUsdExchangeRateSetting: ISystemSetting = result[0][1];
                setInternalUsdExchangeRateSetting(internalUsdExchangeRateSetting);

                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onInternalUsdExchangeRate = (data: ISystemSetting) => {
        data.type = SystemSettingType.InternalExchangeRate;

        serverFetch(`systemSettings`, { method: 'POST', bodyData: data })
            .then(() => {
                internalUsdExchangeRateForm.resetFields();
                setRefreshRequired(true);
                setOpenInternalUsdExchangeRate(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения внутреннего курса', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Flex gap='small' align='center'>
                {setInternalUsdExchangeRate && (
                    <Tooltip title='Задать внутренний курс'>
                        <Button type='primary' icon={<DollarOutlined />} onClick={() => setOpenInternalUsdExchangeRate(true)} />
                    </Tooltip>
                )}
                <Flex gap='middle' align='center'>
                    {useInternalExchangeRate && (
                        <Tag color='#b7eb8f' style={{ fontSize: 18, padding: 5, color: '#000000', fontWeight: 600 }}>
                            Внутренний: {internalUsdExchangeRateSetting ? internalUsdExchangeRateSetting.value : 0}{' '}
                            {currencySign(Currency.Rub)}
                        </Tag>
                    )}
                    {useCBExchangeRate && (
                        <Tag color='#ffee96' style={{ fontSize: 18, padding: 5, color: '#000000', fontWeight: 600 }}>
                            ЦБ: {cbUsdExchangeRate} {currencySign(Currency.Rub)}
                        </Tag>
                    )}
                </Flex>
            </Flex>

            <Modal
                width={250}
                title='Задать внутренний курс'
                open={openInternalUsdExchangeRate}
                okText='ОК'
                closable={false}
                onOk={() => {
                    internalUsdExchangeRateForm.submit();
                }}
                onCancel={() => {
                    internalUsdExchangeRateForm.resetFields();
                    setOpenInternalUsdExchangeRate(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={internalUsdExchangeRateForm} onFinish={onInternalUsdExchangeRate}>
                    <Form.Item name='value'>
                        <InputNumber precision={4} decimalSeparator=',' min={0} stringMode addonAfter={currencySign(Currency.Rub)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ExchangeRateInfo;
