export enum UserAccountType {
    User = 1,
    Transport = 2,
    Transfer = 3,
}

export const enumLabel = (value?: UserAccountType) => {
    switch (value) {
        case UserAccountType.User:
            return 'Личный';
        case UserAccountType.Transport:
            return 'Транспорт';
        case UserAccountType.Transfer:
            return 'Переводы';
        default:
            return '';
    }
};
