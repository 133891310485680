import React, { useState, useEffect } from 'react';

import { Space, Button, Select, Input, Form, Modal, Tooltip, AutoComplete } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { PlusOutlined } from '@ant-design/icons';

import { exception } from '@extensions/notification';
import { IOption } from '@extensions/option';

import { useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { ITradingPlatform } from '@entities/trading-platform';
import { ICountry } from '@entities/country';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITradingPlatformControl {
    defaultPlatformName: string | undefined;
    basePlatforms: Array<ITradingPlatform>;
    country: ICountry;
    disabled: boolean;
    api: NotificationInstance;
    onSelect: (value: string) => void;
    loadPlatforms: () => void;
}

const TradingPlatformControl = (props: ITradingPlatformControl) => {
    const { basePlatforms, defaultPlatformName, loadPlatforms, onSelect, api, disabled, country } = props;

    const [platformForm] = Form.useForm();

    const d = useAppDispatch();

    const [platforms, setPlatforms] = useState<Array<ITradingPlatform>>([]);
    const [options, setOptions] = useState<Array<IOption>>([]);
    const [platformOpen, setPlatformOpen] = useState<boolean>(false);

    useEffect(() => {
        setPlatforms(basePlatforms);
    }, [basePlatforms]);

    useEffect(() => {
        let items: Array<IOption> = [];

        platforms.map((p: ITradingPlatform) => {
            let newItem: IOption = {
                value: `${p.name}`,
                label: `${p.name}`,
            };

            items.push(newItem);
        });

        setOptions(items);
    }, [platforms]);

    const onSavePlatform = (entity: ITradingPlatform) => {
        serverFetch(`tradingplatforms`, { method: 'POST', bodyData: entity })
            .then(() => {
                platformForm.resetFields();
                setPlatformOpen(false);
                loadPlatforms();
            })
            .catch((ex) => {
                exception(api, 'Ошибка сохранения торговой площадки', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Space.Compact style={{ width: '100%' }}>
                <AutoComplete
                    defaultValue={defaultPlatformName}
                    placeholder='Торговая площадка'
                    disabled={disabled}
                    popupMatchSelectWidth={true}
                    options={options}
                    onSelect={onSelect}
                    onSearch={async (value: string) => {
                        var result = basePlatforms?.filter((p) => p.name?.toUpperCase()?.startsWith(value.toUpperCase()));

                        setPlatforms(result || []);
                    }}
                />
                <Tooltip title='Добавить торговую площадку'>
                    <Button icon={<PlusOutlined />} onClick={() => setPlatformOpen(true)} />
                </Tooltip>
            </Space.Compact>

            <Modal
                title='Торговая площадка'
                open={platformOpen}
                okText='ОК'
                onOk={() => platformForm.submit()}
                onCancel={() => {
                    platformForm.resetFields();

                    setPlatformOpen(false);
                }}
            >
                <Form colon={false} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} onFinish={onSavePlatform} form={platformForm}>
                    <Form.Item initialValue={country.id} label='Страна' name='countryId'>
                        <Select autoFocus options={[{ value: country.id, label: country.name }]} disabled={true}></Select>
                    </Form.Item>
                    <Form.Item
                        required
                        label='Название'
                        name='name'
                        rules={[{ required: true, message: 'Укажите название торговой площадки' }]}
                    >
                        <Input
                            onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase())}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default TradingPlatformControl;
