import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, Input, notification, Form, Flex, Modal, Drawer, Button, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    HistoryOutlined,
    SwapOutlined,
    PercentageOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Transactions from '@controls/transactions/transactions';
import ExchangeRateInfo from '@controls/exchange-rate-info/exchange-rate-info';
import DepositingModal from '@controls/balance-details/depositing-modal';
import TransferAccountsModal from '@controls/balance-details/transfer-accounts-modal';
import DebtWriteOffModal from '@controls/balance-details/debt-write-off-modal';
import Account from '@controls/account';
import RefundModal from '@controls/balance-details/refund-modal';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { delayAction, toFinanceString } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserBalanceFilter } from '@entities/user-balance-filter';
import { IUserBalance } from '@entities/user-balance';
import { IUserSession } from '@entities/user-session';

import { enumSign as currencySign } from '@enums/currency';

import { BillIcon, WalletIcon, RefundIcon } from '@icons/index';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'Balances';

const Balances = () => {
    const initFilter: IUserBalanceFilter = {};

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const navigate = useNavigate();
    const resolved = useResolvedPath('../finance');

    const [modal, modalContextHolder] = Modal.useModal();

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const tableRef = useRef<TableRef>(null);
    const [colorForm] = Form.useForm();
    const [internalUsdExchangeRateForm] = Form.useForm();

    const filter = useAppSelector<IUserBalanceFilter>((s) => s.filters[filterContext]);

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalance>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalance>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [totalBalance, setTotalBalance] = useState<number>(0);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openDebtWriteOffDialog, setOpenDebtWriteOffDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);
    const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false);
    const [openTransactions, setOpenTransactions] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('userbalances', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let entities: Array<IUserBalance> = result[0][0];

                setBalances(entities);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (!balances) return;

        let balance: number = 0;

        balances.map((b) => {
            balance += b.amount || 0;
        });

        setTotalBalance(balance);
    }, [balances]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            let entity = balances.find((o) => o.userId == selectedRowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const onRefund = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/refund`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenRefundDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка возвращения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtWriteOff = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/debtwriteoff`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDebtWriteOffDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCalculateDiscount = () => {
        setLoading(true);

        serverFetch(`bills/discount`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка расчета скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 150 }}
                            key='login'
                            placeholder='Логин'
                            value={filter?.login}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, login: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 300 }}
                            key='searchText'
                            placeholder='Контрагент'
                            value={filter?.searchText}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Счета на оплату',
                        key: 'bills',
                        disabled: loading || !currentBalance,
                        icon: <BillIcon />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/bills/${currentBalance?.userId}`);
                        },
                    },
                    {
                        label: 'Рассчитать скидку для всех счетов',
                        key: 'calculate',
                        disabled: loading,
                        icon: <PercentageOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Пересчитать итоговую сумму с учетом скидок для всех клиентов?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCalculateDiscount();
                                },
                            });
                        },
                    },
                ]}
                farControl={<ExchangeRateInfo setInternalUsdExchangeRate={true} api={api} />}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalance> = [
            {
                title: 'Логин',
                width: 200,
                dataIndex: 'userLogin',
                render: (_, record) => {
                    return (
                        <div>
                            <span style={{ marginRight: 5 }}>
                                <a
                                    onClick={() => {
                                        navigate(`${resolved.pathname}/balances/${record?.userId}`);
                                    }}
                                >
                                    {record.userLogin}
                                </a>
                            </span>

                            <Flex align='center' gap='small' style={{ marginTop: 7 }}>
                                <Tooltip placement='topLeft' title='Внести денежные средства на счет'>
                                    <Button
                                        icon={<WalletIcon style={{ fontSize: 16 }} />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenDepositingDialog(true);
                                        }}
                                    />
                                </Tooltip>

                                <Tooltip placement='topLeft' title='Перевод между счетами'>
                                    <Button
                                        icon={<SwapOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenTransferAccountsDialog(true);
                                        }}
                                    />
                                </Tooltip>

                                <Tooltip placement='topLeft' title='Возврат денежных средств'>
                                    <Button
                                        icon={<RefundIcon style={{ fontSize: 16 }} />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenRefundDialog(true);
                                        }}
                                    />
                                </Tooltip>

                                <Tooltip placement='topLeft' title='История операций'>
                                    <Button
                                        icon={<HistoryOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentBalance(balances.find((b) => b.userId == record.userId));
                                            setOpenTransactions(true);
                                        }}
                                    />
                                </Tooltip>
                            </Flex>

                            <Flex align='start' gap='small' vertical={true} style={{ marginTop: 7 }}>
                                {record.useCashPayments && (
                                    <Tag color='green' style={{ color: '#000000' }}>
                                        Наличный
                                    </Tag>
                                )}
                                {record.useNonCashPayments && (
                                    <Tag color='#ffee96' style={{ color: '#000000' }}>
                                        Безналичный
                                    </Tag>
                                )}
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: 'Контрагент',
                width: 350,
                render: (_, record) => {
                    return (
                        <>
                            <div>{record.legalName}</div>
                            <div>{record.inn}</div>
                        </>
                    );
                },
            },
            {
                title: 'Расчётный счёт',
                width: 150,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex gap='small' vertical={true}>
                            {record.userAccounts?.map((a) => {
                                return <Account key={`${a.type}${a.currency}`} type={a.type} currency={a.currency} amount={a.amount} />;
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Долг перед клиентом',
                width: 200,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex gap='small' vertical={true}>
                            {record.userDebts?.map((d) => {
                                return (
                                    <Form colon={false} key={d.id} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
                                        <Form.Item label={d.debtName} style={{ marginBottom: 0 }}>
                                            <span style={{ fontWeight: 600, color: 'var(--main-red)' }}>
                                                {toFinanceString(d.amount || 0, 2)} {currencySign(d.currency)}
                                            </span>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{ offset: 8 }} style={{ marginTop: -10 }}>
                                            <span style={{ color: 'var(--main-red)' }}>
                                                {toFinanceString(d.calcAmount || 0, 2)} {currencySign(d.currency)}
                                            </span>
                                        </Form.Item>
                                    </Form>
                                );
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Баланс $',
                width: 200,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        color: '#FFFFFF',
                        background: record.amount >= 0 ? 'var(--main-green)' : 'var(--main-red)',
                    },
                }),
                render: (_, record) => {
                    return <span>{toFinanceString(record.amount || 0, 2)}</span>;
                },
            },
            {},
        ];

        return (
            <Table
                ref={tableRef}
                rowKey='userId'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={balances}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                    type: 'radio',
                }}
                onRow={(record: IUserBalance) => {
                    return {
                        onClick: (event) => {
                            if (!record.userId) return;

                            onSelectChange([record.userId]);
                        },
                    };
                }}
                virtual={true}
                footer={() => {
                    return (
                        <Flex justify='start' style={{ fontWeight: 700, fontSize: 16 }} align='center'>
                            <span>Итого:</span>
                            <span style={{ marginLeft: 5, color: totalBalance >= 0 ? 'var(--main-green)' : 'var(--main-red)' }}>
                                {toFinanceString(totalBalance, 2)} $
                            </span>
                        </Flex>
                    );
                }}
                scroll={{ y: height }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 233px - ${filterHeight}px + ${showFilter ? 0 : 16}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {currentBalance && openDepositingDialog && (
                <DepositingModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {currentBalance && openDebtWriteOffDialog && (
                <DebtWriteOffModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    onCancel={() => setOpenDebtWriteOffDialog(false)}
                    onSave={(value: ITransactionParams) => onDebtWriteOff(value)}
                    api={api}
                />
            )}

            {currentBalance && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    accounts={currentBalance.userAccounts || []}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            {currentBalance && openRefundDialog && (
                <RefundModal
                    userId={currentBalance.userId}
                    userLogin={currentBalance.userLogin}
                    accounts={currentBalance.userAccounts || []}
                    onCancel={() => setOpenRefundDialog(false)}
                    onSave={(value: ITransactionParams) => onRefund(value)}
                    api={api}
                />
            )}

            {currentBalance && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${currentBalance.userLogin}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={currentBalance.userId} isViewMode={false} onRefresh={() => setRefreshRequired(true)} />
                </Drawer>
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Balances;
